import React, { useEffect, useState, useRef } from 'react';
import baseURL from '../url';
import './TiendasPage.css';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';
import LoadingTiendas from '../LoadingTiendas/LoadingTiendas';
import { Link as Anchor } from "react-router-dom";
import moneda from '../moneda';
import Banners from '../Banners/Banners';
SwiperCore.use([Navigation, Pagination, Autoplay]);

export default function TiendasPage() {
    const [loading, setLoading] = useState(true);
    const [tiendas, setTiendas] = useState([]);
    const [productos, setProductos] = useState([]);
    const [categorias, setCategorias] = useState([]);

    useEffect(() => {
        cargarTiendas();
        cargarCategoria();
    }, []);

    const cargarTiendas = () => {
        fetch(`${baseURL}/tiendasFrontend.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setTiendas(data.tiendas.sort(() => 0.5 - Math.random()) || []);
                const productosTienda = data.tiendas
                    .flatMap(tienda => tienda?.productos || [])

                setProductos(productosTienda);
                setLoading(false);
            })
            .catch(error => console.error('Error al cargar datos:', error));
    };

    const cargarCategoria = () => {
        fetch(`${baseURL}/categoriaTiendaGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setCategorias(data.categorias || []);
            })
            .catch(error => console.error('Error al cargar categorías:', error));
    };
    const obtenerImagen = (item) => {
        if (item.imagen1) {
            return item.imagen1;
        } else if (item.imagen2) {
            return item.imagen2;
        } else if (item.imagen3) {
            return item.imagen3;
        } else if (item.imagen4) {
            return item.imagen4;
        }
        return null;
    };

    return (
        <div className='TiendaContain'>
            <Banners />

            {!loading && tiendas.filter(item => productos.some(prod => prod.idTienda === item.idTienda)).map(item => (
                <div key={item.idTienda}>
                    <Anchor className='textTienda' to={`/tienda/${item.idTienda}/${item.nombre.replace(/\s+/g, '-')}`} >
                        <div className='textTienda'>
                            <img src={item.imagen1} alt="imagen" />
                            <div>
                                <h4>{item.nombre}</h4>
                                {categorias
                                    .filter(categoriaFiltrada => categoriaFiltrada.idCategoria === item.idCategoria)
                                    .map(categoriaFiltrada => (
                                        <h6 key={categoriaFiltrada.idCategoria}> {categoriaFiltrada.categoria}</h6>
                                    ))}
                            </div>
                        </div>
                        <FontAwesomeIcon icon={faAngleDoubleRight} className='iconCardTienda' />
                    </Anchor>

                    <Swiper
                        effect={'coverflow'}
                        grabCursor={true}
                        slidesPerView={'auto'}
                        autoplay={{ delay: 3000 }}
                        id='SwiperSlide-scroll-tiendas-products-Contain'
                    >
                        {productos.filter(filterProd => filterProd.idTienda === item.idTienda).map(prod => (
                            <SwiperSlide id='SwiperSlide-scroll-tiendas-products' key={prod.idProducto}>
                                <Anchor to={`/producto/${prod.idProducto}/${prod.titulo.replace(/\s+/g, '-')}`}>
                                    <img src={obtenerImagen(prod)} alt="imagen" />

                                    <div className='cardTextProduct'>
                                        <h4>{prod.titulo}</h4>
                                        <div className='deFLexPrice'>
                                            <h5> {moneda} {String(prod?.precio)?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</h5>
                                            {(prod.precioAnterior >= 1 && prod.precioAnterior !== undefined) && (
                                                <h5 className='precioTachado'>{moneda} {`${prod?.precioAnterior}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</h5>
                                            )}
                                        </div>
                                        <FontAwesomeIcon icon={faAngleDoubleRight} className='iconCard' />
                                    </div>
                                </Anchor>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            ))}

        </div >
    );
}

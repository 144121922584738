import React, { useEffect, useState } from 'react';
import baseURL from '../url';
import './Tiendas.css';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';
import LoadingTiendas from '../LoadingTiendas/LoadingTiendas';
import { Link as Anchor } from "react-router-dom";
import ProductsHome from '../ProductsHome/ProductsHome';
SwiperCore.use([Navigation, Pagination, Autoplay]);

export default function Tiendas() {
    const [loading, setLoading] = useState(true);
    const [tiendas, setTiendas] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [categoriaSeleccionada, setCategoriaSeleccionada] = useState('Tiendas');

    useEffect(() => {
        cargarTiendas();
        cargarCategoria();
    }, []);

    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    const cargarTiendas = () => {
        fetch(`${baseURL}/tiendasFrontend.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                const tiendasData = data.tiendas || [];
                const tiendasAleatorias = shuffleArray(tiendasData);
                setTiendas(tiendasAleatorias);
                setLoading(false);
            })
            .catch(error => console.error('Error al cargar datos:', error));
    };

    const cargarCategoria = () => {
        fetch(`${baseURL}/categoriaTiendaGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setCategorias(data.categorias || []);
            })
            .catch(error => console.error('Error al cargar categorías:', error));
    };

    const handleClickCategoria = (categoria) => {
        setCategoriaSeleccionada(categoria);
    };

    // Filtrar categorías que tienen tiendas asociadas
    const categoriasFiltradas = categorias.filter(categoria =>
        tiendas.some(tienda => tienda.idCategoria === categoria.idCategoria)
    );

    return (
        <div className='TiendaContain' id='TiendaContain'>

            {tiendas.length > 0 && (
                <div className={'categoriasInputs'}>
                    <input
                        type="button"
                        value="Tiendas"
                        onClick={() => handleClickCategoria('Tiendas')}
                        style={{
                            backgroundColor: categoriaSeleccionada === 'Tiendas' ? '#0c71cf' : '',
                            color: categoriaSeleccionada === 'Tiendas' ? '#fff' : '',
                            borderBottom: categoriaSeleccionada === 'Tiendas' ? '2px solid #0c71cf' : 'none'
                        }}
                    />
                    {categoriasFiltradas.map(({ categoria, idCategoria }) => (
                        <input
                            key={idCategoria}
                            type="button"
                            value={categoria}
                            onClick={() => handleClickCategoria(idCategoria)}
                            style={{
                                backgroundColor: categoriaSeleccionada === idCategoria ? '#0c71cf' : '',
                                color: categoriaSeleccionada === idCategoria ? '#fff' : '',
                                borderBottom: categoriaSeleccionada === idCategoria ? '2px solid #0c71cf' : 'none'
                            }}
                        />
                    ))}
                </div>
            )}

            {loading ? (
                <LoadingTiendas />
            ) : (
                <div>
                    {categoriaSeleccionada === 'Tiendas' && (
                        <Swiper
                            effect={'coverflow'}
                            grabCursor={true}
                            slidesPerView={'auto'}
                            id='swiper_container_tiendas'
                            autoplay={{ delay: 3000 }}
                        >
                            {tiendas.map(item => (
                                <SwiperSlide id='SwiperSlide-scroll-tiendas' key={item.idTienda}>
                                    <Anchor className='cardTienda' to={`/tienda/${item.idTienda}/${item.nombre.replace(/\s+/g, '-')}`}>
                                        <img src={item.imagen2} alt="imagen" className='imgTiendaBanner' />
                                        <div className='cardTextTienda'>
                                            <img src={item.imagen1} alt="imagen" className='CardlogoTienda' />
                                            {
                                                categorias
                                                    .filter(categoriaFiltrada => categoriaFiltrada.idCategoria === item.idCategoria)
                                                    .map(categoriaFiltrada => (
                                                        <h6 key={categoriaFiltrada.idCategoria}> {categoriaFiltrada.categoria}</h6>
                                                    ))
                                            }
                                            <div>
                                                <h4>{item.nombre}</h4>
                                                <h5>{item.direccion}</h5>
                                            </div>
                                            <FontAwesomeIcon icon={faAngleDoubleRight} className='iconCard' />
                                        </div>
                                    </Anchor>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    )}
                    {categoriaSeleccionada !== 'Tiendas' && (
                        <Swiper
                            effect={'coverflow'}
                            grabCursor={true}
                            slidesPerView={'auto'}
                            id='swiper_container_tiendas'
                            autoplay={{ delay: 3000 }}
                        >
                            {tiendas
                                .filter(item => item.idCategoria === categoriaSeleccionada)
                                .map(item => (
                                    <SwiperSlide id='SwiperSlide-scroll-tiendas' key={item.idTienda}>
                                        <Anchor className='cardTienda' to={`/tienda/${item.idTienda}/${item.nombre.replace(/\s+/g, '-')}`}>
                                            <img src={item.imagen2} alt="imagen" className='imgTiendaBanner' />
                                            <div className='cardTextTienda'>
                                                <img src={item.imagen1} alt="imagen" className='CardlogoTienda' />
                                                {
                                                    categorias
                                                        .filter(categoriaFiltrada => categoriaFiltrada.idCategoria === item.idCategoria)
                                                        .map(categoriaFiltrada => (
                                                            <h6 key={categoriaFiltrada.idCategoria}> {categoriaFiltrada.categoria}</h6>
                                                        ))
                                                }
                                                <div>
                                                    <h4>{item.nombre}</h4>
                                                    <h5>{item.direccion}</h5>
                                                </div>
                                                <FontAwesomeIcon icon={faAngleDoubleRight} className='iconCard' />
                                            </div>
                                        </Anchor>
                                    </SwiperSlide>
                                ))}
                        </Swiper>
                    )}
                </div>
            )}
            <ProductsHome />
        </div>
    );
}

import React, { useEffect, useState, useRef } from 'react';
import { Link as Anchor, useParams } from 'react-router-dom';
import baseURL from '../url';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import AuthUsuario from '../AuthUsuario/AuthUsuario'
export default function FooterTienda() {
    const [tienda, setTienda] = useState(null);
    const [productos, setProductos] = useState([]);
    const { idTienda } = useParams();
    const [loading, setLoading] = useState(true);
    const [categorias, setCategorias] = useState([]);

    useEffect(() => {
        cargarTiendas();
        cargarCategorias();
    }, [idTienda]);

    const cargarTiendas = () => {
        fetch(`${baseURL}/tiendasFrontend.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                const tiendaEncontrada = data?.tiendas?.find(t => t.idTienda === parseInt(idTienda));
                const productosTienda = data.tiendas
                    .flatMap(tienda => tienda?.productos.slice(0, 3) || [])
                    .filter(producto => producto.idTienda === parseInt(idTienda));

                setProductos(productosTienda);
                setTienda(tiendaEncontrada);
                setLoading(false);
            })
            .catch(error => console.error('Error al cargar datos:', error));
    };

    const cargarCategorias = () => {
        fetch(`${baseURL}/categoriasGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setCategorias(data.categorias || []);
            })
            .catch(error => console.error('Error al cargar categorías:', error));
    };


    return (
        <div className='FooterContain'>
            <div className='footerText'>
                <Anchor to='' target="_blank"> <img src={tienda?.imagen1} alt="logo" /></Anchor>
                <h2>{tienda?.nombre}</h2>
                <div className='socials'>
                    {tienda?.instagram && (
                        <Anchor to={tienda.instagram} target="_blank"><i className='fa fa-instagram'></i></Anchor>
                    )}
                    {tienda?.telefono && (
                        <Anchor to={`tel:${tienda.telefono}`} target="_blank"><i className='fa fa-whatsapp'></i></Anchor>
                    )}
                    {tienda?.facebook && (
                        <Anchor to={tienda.facebook} target="_blank"><i className='fa fa-facebook'></i></Anchor>
                    )}

                </div>
            </div>
            <div className='footerText'>
                <h3>Contacto</h3>
                {tienda?.email && (
                    <Anchor to={`mailto:${tienda.email}`} target="_blank">{tienda.email}</Anchor>
                )}
                {tienda?.telefono && (
                    <Anchor to={`tel:${tienda.telefono}`} target="_blank">{tienda.telefono}</Anchor>
                )}
                {tienda?.direccion && (
                    <Anchor to={`https://www.google.com/maps?q=${encodeURIComponent(tienda.direccion)}`} target="_blank">{tienda.direccion}</Anchor>
                )}
            </div>
            <div className='footerText'>
                <h3>Productos</h3>
                {productos.map(item => (
                    <Anchor key={item.idProducto} to={`/producto/${item.idProducto}/${item.titulo.replace(/\s+/g, '-')}`}>{item?.titulo?.slice(0, 33)}</Anchor>
                ))}
            </div>
            <div className='footerText'>
                <h3>Acceso</h3>
                <Anchor to={'/dashboard'} className='authButton'> <FontAwesomeIcon icon={faUser} /> Dashboard</Anchor>
                <AuthUsuario />

            </div>
        </div>
    );
}

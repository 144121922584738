import React, { useEffect, useState } from 'react';
import baseURL from '../url';
import './BannersTiendas.css';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'react-toastify/dist/ReactToastify.css';
import LoadingTiendas from '../LoadingTiendas/LoadingTiendas';
import { Link as Anchor } from "react-router-dom";

SwiperCore.use([Navigation, Pagination, Autoplay]);

export default function BannersTiendas() {
    const [loading, setLoading] = useState(true);
    const [tiendas, setTiendas] = useState([]);

    useEffect(() => {
        cargarTiendas();
    }, []);

    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    const cargarTiendas = () => {
        fetch(`${baseURL}/tiendasFrontend.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                const tiendasData = data.tiendas || [];
                const tiendasAleatorias = shuffleArray(tiendasData).slice(0, 4);
                setTiendas(tiendasAleatorias);
                setLoading(false);
            })
            .catch(error => console.error('Error al cargar datos:', error));
    };

    return (
        <div className='BannersTiendas' >
            {loading ? (
                <Swiper
                    effect={'coverflow'}
                    grabCursor={true}
                    slidesPerView={'auto'}
                    id='swiper_container_tiendas'
                    autoplay={{ delay: 3000 }}
                >

                    <SwiperSlide id='SwiperSlide-scroll-banner-tiendasLoading' >

                    </SwiperSlide>
                    <SwiperSlide id='SwiperSlide-scroll-banner-tiendasLoading' >

                    </SwiperSlide>
                    <SwiperSlide id='SwiperSlide-scroll-banner-tiendasLoading' >

                    </SwiperSlide>

                </Swiper>
            ) : (
                <Swiper
                    effect={'coverflow'}
                    grabCursor={true}
                    slidesPerView={'auto'}
                    id='swiper_container_tiendas'
                    autoplay={{ delay: 3000 }}
                >
                    {tiendas.map(item => (
                        <SwiperSlide id='SwiperSlide-scroll-banner-tiendas' key={item.idTienda}>
                            <Anchor to={`/tienda/${item.idTienda}/${item.nombre.replace(/\s+/g, '-')}`}>
                                <img src={item.imagen2} alt="imagen" className='imgTiendaBanner' />
                            </Anchor>
                        </SwiperSlide>
                    ))}
                </Swiper>
            )}
        </div>
    );
}

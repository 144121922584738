import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faArrowUp, faArrowDown, faSync, faEye } from '@fortawesome/free-solid-svg-icons';
import baseURL from '../../url';
import './TiendaUser.css'
import { Link as Anchor } from "react-router-dom";

export default function TiendaUser() {
    const [usuario, setUsuario] = useState({});
    const [tiendas, setTiendas] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        cargarTiendas();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${baseURL}/userLogued.php`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setUsuario(data);
            } catch (error) {
                console.error
                    ('Error al obtener datos:', error);
            }
        };

        fetchData();
    }, []);
    const cargarTiendas = () => {
        fetch(`${baseURL}/tiendaGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setTiendas(data.tiendas || []);
                console.log(data.tiendas);
                setLoading(false);
            })
            .catch(error => console.error('Error al cargar datos:', error));

    };

    return (
        <div className='TiendaUser'>

            {loading ? (
                <div className='TiendaUserCont' >
                    <div className='bannerTiendaUserLoading'>

                    </div>
                    <div className='logoTiendaUserLoading'>

                    </div>
                    <div className='textTiendaLoading'>

                    </div>
                </div>
            ) : <>
                {tiendas?.filter(item => item.idUsuario === usuario.idUsuario).map(item => (
                    <Anchor className='TiendaUserCont' to={`/tienda/${item?.idTienda}/${item?.nombre?.replace(/\s+/g, '-')}`}>
                        <div className='bannerTiendaUser'>
                            <img src={item?.imagen2} alt="" />
                        </div>
                        <img src={item?.imagen1} alt="" className='logoTiendaUser' />
                        <h3>{item?.nombre}</h3>
                    </Anchor>
                ))}
            </>}
        </div>
    )
}

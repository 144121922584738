import React from 'react'
import PageTiendas from '../../Components/TiendasPage/TiendasPage'
export default function TiendasPage() {
    return (
        <div>

            <PageTiendas />

        </div>
    )
}

import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faArrowUp, faArrowDown, faSync, faEye } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import baseURL from '../../url';
import NewTienda from '../NewTienda/NewTienda';
import './TiendasData.css'
import { Link as Anchor } from "react-router-dom";
export default function TiendasData() {
    const [tiendas, setTiendas] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [nuevoNombre, setNuevoNombre] = useState('');
    const [nuevoTelefono, setNuevoTelefono] = useState('');
    const [nuevoInstagram, setNuevoInstagram] = useState('');
    const [nuevoEmail, setNuevoEmail] = useState('');
    const [nuevaDireccion, setNuevaDieccion] = useState('');
    const [nuevofacebook, setNuevofacebook] = useState('');
    const [nuevaCategoria, setNuevaCategoria] = useState('');
    const [tienda, setTienda] = useState({});
    const [modalImagenVisible, setModalImagenVisible] = useState(false);
    const [imagenSeleccionada, setImagenSeleccionada] = useState('');
    const [filtroId, setFiltroId] = useState('');
    const [filtroEstado, setFiltroEstado] = useState('');
    const [filtroNombre, setFiltroNombre] = useState('');
    const [filtroCategoria, setFiltroCategoria] = useState('');
    const [ordenInvertido, setOrdenInvertido] = useState(false);
    const [imagenPreview, setImagenPreview] = useState(null);
    const [imagenPreview2, setImagenPreview2] = useState(null);
    const [nuevaImagen, setNuevaImagen] = useState(null);
    const [nuevaImagen2, setNuevaImagen2] = useState(null);
    const [selectedSection, setSelectedSection] = useState('texto');
    const [categorias, setCategorias] = useState([]);
    const [usuario, setUsuario] = useState({});
    const [nuevoEstado, setNuevoEstado] = useState('');
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${baseURL}/userLogued.php`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setUsuario(data);
            } catch (error) {
                console.error
                    ('Error al obtener datos:', error);
            }
        };

        fetchData();
    }, []);
    const cerrarModalImagen = () => {
        setModalImagenVisible(false);
    };
    const abrirModalImagenSeleccionada = (imagen) => {
        setImagenSeleccionada(imagen);
        setModalImagenVisible(true);
    };


    useEffect(() => {
        cargarTiendas();
        cargarCategoria();
    }, []);


    const cargarTiendas = () => {
        fetch(`${baseURL}/tiendaGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setTiendas(data.tiendas || []);
                console.log(data.tiendas);
            })
            .catch(error => console.error('Error al cargar datos:', error));
    };



    const cargarCategoria = () => {
        fetch(`${baseURL}/categoriaTiendaGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setCategorias(data.categorias || []);
                console.log(data.categorias)
            })
            .catch(error => console.error('Error al cargar contactos:', error));
    };
    const eliminar = (idTienda) => {
        // Reemplaza el window.confirm con SweetAlert2
        Swal.fire({
            title: '¿Estás seguro?',
            text: '¡No podrás revertir esto!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${baseURL}/tiendaDelete.php?idTienda=${idTienda}`, {
                    method: 'DELETE',
                })
                    .then(response => response.json())
                    .then(data => {
                        Swal.fire(
                            '¡Eliminado!',
                            data.mensaje,
                            'success'
                        );
                        cargarTiendas();
                    })
                    .catch(error => {
                        console.error('Error al eliminar la Producto:', error);
                        toast.error(error);
                    });
            }
        });
    };

    const abrirModal = (item) => {
        setTienda(item);
        setNuevoNombre(item.nombre);
        setNuevoTelefono(item.telefono);
        setNuevoInstagram(item.instagram);
        setNuevoEmail(item.email);
        setNuevaDieccion(item.direccion);
        setNuevofacebook(item.facebook);
        setNuevaCategoria(item.idCategoria);
        setNuevoEstado(item.estado);
        setModalVisible(true);
    };

    const cerrarModal = () => {
        setModalVisible(false);
    };

    const filtrados = tiendas.filter(item => {
        const idMatch = item.idTienda.toString().includes(filtroId);
        const NombreMatch = !filtroNombre || item.nombre.toLowerCase().indexOf(filtroNombre.toLowerCase()) !== -1;
        const estadoMatch = !filtroEstado || item.estado === filtroEstado;
        const categoriaMatch = item.idCategoria.toString().includes(filtroCategoria);
        return idMatch && NombreMatch && categoriaMatch && estadoMatch;
    });

    const descargarExcel = () => {
        const data = filtrados.map(item => ({
            IdTienda: item.idTienda,
            IdUsuario: item.idUsuario,
            Nombre: item.nombre,
            Telefono: item.telefono,
            Instagram: item.instagram,
            Facebook: item.facebook,
            Email: item.email,
            Direccion: item.direccion,
            Fecha: item.createdAt,


        }));

        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Tiendas');
        XLSX.writeFile(wb, 'tiendas.xlsx');
    };

    const descargarPDF = () => {
        const pdf = new jsPDF();
        pdf.text('Lista de tiendas', 10, 10);

        const columns = [
            { title: 'IdTienda', dataKey: 'idTienda' },
            { title: 'IdUsuario', dataKey: 'idTienda' },
            { title: 'Nombre', dataKey: 'nombre' },
            { title: 'Telefono', dataKey: 'telefono' },
            { title: 'Instagram', dataKey: 'instagram' },
            { title: 'Facebook', dataKey: 'facebook' },
            { title: 'Email', dataKey: 'email' },
            { title: 'Direccion', dataKey: 'direccion' },
            { title: 'Fecha', dataKey: 'createdAt' },
        ];

        const data = filtrados.map(item => ({
            IdTienda: item.idTienda,
            IdUsuario: item.idUsuario,
            Nombre: item.nombre,
            Telefono: item.telefono,
            Instagram: item.instagram,
            Facebook: item.facebook,
            Email: item.email,
            Direccion: item.direccion,
            Fecha: item.createdAt,

        }));

        pdf.autoTable({
            head: [columns.map(col => col.title)],
            body: data.map(item => Object.values(item)),
        });

        pdf.save('tiendas.pdf');
    };

    const recargar = () => {
        cargarTiendas();
    };
    const invertirOrden = () => {
        setTiendas([...tiendas].reverse());
        setOrdenInvertido(!ordenInvertido);
    };


    const handleUpdateText = (idTienda) => {
        const payload = {
            nuevoNombre: nuevoNombre !== '' ? nuevoNombre : tienda.nombre,
            nuevoTelefono: nuevoTelefono !== '' ? nuevoTelefono : tienda.telefono,
            nuevoInstagram: nuevoInstagram !== '' ? nuevoInstagram : tienda.instagram,
            nuevoEmail: nuevoEmail !== '' ? nuevoEmail : tienda.email,
            nuevaDireccion: nuevaDireccion !== '' ? nuevaDireccion : tienda.direccion,
            nuevoFacebook: nuevofacebook !== '' ? nuevofacebook : tienda.facebook,
            nuevoidCategoria: nuevaCategoria !== '' ? nuevaCategoria : tienda.idCategoria,
            nuevoEstado: nuevoEstado !== '' ? nuevoEstado : tienda.estado,
        };

        fetch(`${baseURL}/tiendaTextPut.php?idTienda=${idTienda}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(data => {
                if (data.error) {

                    Swal.fire(
                        'Error!',
                        data.error,
                        'error'
                    );
                } else {

                    Swal.fire(
                        'Editado!',
                        data.mensaje,
                        'success'
                    );
                    recargar();
                    cerrarModal()
                }
            })
            .catch(error => {
                console.log(error.message);
                toast.error(error.message);
            });
    };

    const handleFileChange = (event, setFile, setPreview) => {
        const file = event.target.files[0];

        if (file) {
            // Crear una URL de objeto para la imagen seleccionada
            const previewURL = URL.createObjectURL(file);
            setFile(file);
            setPreview(previewURL);
        }
    };
    const handleEditarImagenBanner = (idTienda) => {
        const formData = new FormData();
        formData.append('idTienda', idTienda);
        formData.append('updateAction', 'update'); // Campo adicional para indicar que es una actualización

        if (nuevaImagen) {
            formData.append('imagen1', nuevaImagen);
        }
        if (nuevaImagen2) {
            formData.append('imagen2', nuevaImagen2);
        }

        fetch(`${baseURL}/tiendaImagePut.php`, {
            method: 'POST',  // Cambiado a POST
            body: formData
        })
            .then(response => {
                // Manejar el caso cuando la respuesta no es un JSON válido o está vacía
                if (!response.ok) {
                    throw new Error('La solicitud no fue exitosa');

                }

                return response.json();
            })
            .then(data => {
                if (data.error) {

                    toast.error(data.error);
                    console.log(formData)
                } else {

                    toast.success(data.mensaje);
                    window.location.reload();
                }
            })
            .catch(error => {
                console.log(error)
                toast.error(error.message);
                console.log(formData)
                console.log(idTienda)
            });
    };

    const handleSectionChange = (section) => {
        setSelectedSection(section);
    };


    return (
        <div>

            <ToastContainer />
            <div className='deFlexContent'>

                <div className='deFlex2'>
                    <NewTienda />
                    {
                        usuario?.rol === 'admin' &&
                        <>
                            <button className='excel' onClick={descargarExcel}><FontAwesomeIcon icon={faArrowDown} /> Excel</button>
                            <button className='pdf' onClick={descargarPDF}><FontAwesomeIcon icon={faArrowDown} /> PDF</button>
                        </>
                    }
                </div>
                <div className='filtrosContain'>
                    <div className='inputsColumn'>
                        <input type="number" value={filtroId} onChange={(e) => setFiltroId(e.target.value)} placeholder='Id Tienda' />
                    </div>
                    <div className='inputsColumn'>
                        <input type="text" value={filtroNombre} onChange={(e) => setFiltroNombre(e.target.value)} placeholder='Nombre' />
                    </div>
                    <div className='inputsColumn'>
                        <select value={filtroCategoria} onChange={(e) => setFiltroCategoria(e.target.value)}>
                            <option value="">Categorias</option>
                            {
                                categorias.map(item => (
                                    <option value={item?.idCategoria}>{item?.categoria}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className='inputsColumn'>
                        <select value={filtroEstado} onChange={(e) => setFiltroEstado(e.target.value)}>
                            <option value="">Estado</option>
                            <option value='visible'>Visible</option>
                            <option value='no_visible'>No visible</option>
                        </select>
                    </div>
                    <button className='reload' onClick={recargar}><FontAwesomeIcon icon={faSync} /></button>
                    <button className='reverse' onClick={invertirOrden}>
                        {ordenInvertido ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />}
                    </button>

                </div>

            </div>


            {modalImagenVisible && (
                <div className="modalImg">
                    <div className="modal-contentImg">


                        <span className="close2" onClick={cerrarModalImagen}>
                            &times;
                        </span>

                        <img src={imagenSeleccionada} alt="Imagen Seleccionada" />
                    </div>
                </div>
            )}

            {modalVisible && (
                <div className="modal">
                    <div className="modal-content">
                        <div className='deFlexBtnsModal'>

                            <div className='deFlexBtnsModal'>
                                <button
                                    className={selectedSection === 'texto' ? 'selected' : ''}
                                    onClick={() => handleSectionChange('texto')}
                                >
                                    Editar Texto
                                </button>
                                <button
                                    className={selectedSection === 'imagenes' ? 'selected' : ''}
                                    onClick={() => handleSectionChange('imagenes')}
                                >
                                    Editar Imagenes
                                </button>
                            </div>
                            <span className="close" onClick={cerrarModal}>
                                &times;
                            </span>
                        </div>
                        <div className='sectiontext' style={{ display: selectedSection === 'texto' ? 'flex' : 'none' }}>
                            <div className='flexGrap'>
                                <fieldset>
                                    <legend>Nombre</legend>
                                    <input
                                        type="text"
                                        value={nuevoNombre !== '' ? nuevoNombre : tienda.nombre}
                                        onChange={(e) => setNuevoNombre(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Telefono</legend>
                                    <input
                                        type="number"
                                        value={nuevoTelefono !== '' ? nuevoTelefono : tienda.telefono}
                                        onChange={(e) => setNuevoTelefono(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Instagram</legend>
                                    <input
                                        type="url"
                                        value={nuevoInstagram !== '' ? nuevoInstagram : tienda.instagram}
                                        onChange={(e) => setNuevoInstagram(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset >
                                    <legend>email</legend>
                                    <input
                                        type="email"
                                        value={nuevoEmail !== '' ? nuevoEmail : tienda.email}
                                        onChange={(e) => setNuevoEmail(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Categoria</legend>
                                    <select
                                        value={nuevaCategoria !== '' ? nuevaCategoria : tienda.categoria}
                                        onChange={(e) => setNuevaCategoria(e.target.value)}
                                    >

                                        {
                                            categorias
                                                .filter(categoriaFiltrada => categoriaFiltrada.idCategoria === tienda.idCategoria)
                                                .map(categoriaFiltrada => (

                                                    <option value={tienda.categoria}> {categoriaFiltrada.categoria}</option>
                                                ))
                                        }

                                        {
                                            categorias.map(item => (
                                                <option value={item?.idCategoria}>{item?.categoria}</option>
                                            ))
                                        }
                                    </select>
                                </fieldset>

                                <fieldset>
                                    <legend>Estado</legend>
                                    <select
                                        value={nuevoEstado !== '' ? nuevoEstado : tienda.estado}
                                        onChange={(e) => setNuevoEstado(e.target.value)}
                                    >
                                        <option value={tienda?.estado}>{tienda?.estado}</option>
                                        <option value='visible'>Visible</option>
                                        <option value='no_visible'>No visible</option>
                                    </select>
                                </fieldset>
                                <fieldset >
                                    <legend>Direccion</legend>
                                    <input
                                        type="text"
                                        value={nuevaDireccion !== '' ? nuevaDireccion : tienda.direccion}
                                        onChange={(e) => setNuevaDieccion(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset >
                                    <legend>Facebook</legend>
                                    <input
                                        type="text"
                                        value={nuevofacebook !== '' ? nuevofacebook : tienda.facebook}
                                        onChange={(e) => setNuevofacebook(e.target.value)}
                                    />
                                </fieldset>

                            </div>
                            <button className='btnPost' onClick={() => handleUpdateText(tienda.idTienda)} >Guardar </button>
                        </div>



                        <div className='sectionImg' style={{ display: selectedSection === 'imagenes' ? 'flex' : 'none' }}>
                            <div id='previevImagesLogoBanner'>
                                {imagenPreview2 ? (
                                    <img src={imagenPreview2} alt="Vista previa de la imagen" onClick={() => abrirModalImagenSeleccionada(tienda.imagen2)} id='previevImagesBanner' />
                                ) : (
                                    <>
                                        {tienda.imagen2 ? (
                                            <img src={tienda.imagen2} alt="imagen" onClick={() => abrirModalImagenSeleccionada(tienda.imagen2)} id='previevImagesBanner' />

                                        ) : (
                                            <span className='imgNone'>
                                                No hay imagen

                                            </span>
                                        )}
                                    </>
                                )}

                                {imagenPreview ? (
                                    <img src={imagenPreview} alt="Vista previa de la imagen" onClick={() => abrirModalImagenSeleccionada(tienda.imagen1)} id='previevImagesLogo' />
                                ) : (
                                    <>
                                        {tienda.imagen1 ? (
                                            <img src={tienda.imagen1} alt="imagen" onClick={() => abrirModalImagenSeleccionada(tienda.imagen1)} id='previevImagesLogo' />

                                        ) : (
                                            <span className='imgNone'>
                                                No hay imagen

                                            </span>
                                        )}
                                    </>
                                )}


                            </div>

                            <div className='flexGrap'>
                                <fieldset>
                                    <legend>Editar Logo </legend>
                                    <input type="file" accept="image/*" onChange={(e) => handleFileChange(e, setNuevaImagen, setImagenPreview)} />
                                </fieldset>
                                <fieldset>
                                    <legend>Editar Banner </legend>
                                    <input type="file" accept="image/*" onChange={(e) => handleFileChange(e, setNuevaImagen2, setImagenPreview2)} />
                                </fieldset>
                            </div>
                            <button className='btnPost' onClick={() => handleEditarImagenBanner(tienda.idTienda)}>Guardar </button>
                        </div>
                    </div>







                </div>
            )}
            <div className='table-container'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Id Tienda</th>
                            <th>Id Usuario</th>
                            <th>Estado</th>
                            <th>Categoria</th>
                            <th>Nombre</th>
                            <th>Email</th>
                            <th>Logo</th>
                            <th>Banner</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>

                    <tbody>
                        {filtrados.filter(item => usuario.rol === 'admin' || item.idUsuario === usuario.idUsuario).map(item => (
                            <tr key={item.idTienda}>
                                <td>{item.idTienda}</td>
                                <td>{item.idUsuario}</td>
                                <td style={{
                                    color: item?.estado === 'visible' ? '#008000' : item?.estado === 'no_visible' ? '#FF0000' : '#000000',
                                }}>
                                    {`${item?.estado}`}
                                </td>

                                {categorias
                                    .filter(categoriaFiltrada => categoriaFiltrada.idCategoria === item.idCategoria)
                                    .map(categoriaFiltrada => (
                                        <td
                                            key={categoriaFiltrada.idCategoria}
                                            style={{ color: '#DAA520' }}
                                        >
                                            {categoriaFiltrada.categoria}
                                        </td>
                                    ))
                                }
                                <td>{item.nombre}</td>
                                <td>{item.email}</td>
                                <td>
                                    {item.imagen1 ? (
                                        <img src={item.imagen1} alt="imagen1" />
                                    ) : (
                                        <span className='imgNonetd'>
                                            Sin imagen
                                        </span>
                                    )}
                                </td>
                                <td>
                                    {item.imagen2 ? (
                                        <img src={item.imagen2} alt="imagen2" />
                                    ) : (
                                        <span className='imgNonetd'>
                                            Sin imagen
                                        </span>
                                    )}
                                </td>
                                <td>
                                    <button className='eliminar' onClick={() => eliminar(item.idTienda)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                    <button className='editar' onClick={() => abrirModal(item)}>
                                        <FontAwesomeIcon icon={faEdit} />
                                    </button>
                                    <Anchor className='editar' to={`/tienda/${item?.idTienda}/${item?.nombre?.replace(/\s+/g, '-')}`}>
                                        <FontAwesomeIcon icon={faEye} />
                                    </Anchor>
                                </td>
                            </tr>
                        ))}
                    </tbody>

                </table>
            </div>
        </div>
    );
};

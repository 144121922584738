import React, { useState, useEffect } from 'react';
import './NewTienda.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import baseURL from '../../url';

export default function NewTienda() {
    const [mensaje, setMensaje] = useState('');
    const [imagenPreview1, setImagenPreview1] = useState(null);
    const [imagenPreview2, setImagenPreview2] = useState(null);
    const [nombre, setNombre] = useState('');
    const [telefono, setTelefono] = useState('');
    const [instagram, setInstagram] = useState('');
    const [email, setEmail] = useState('');
    const [direccion, setDireccion] = useState('');
    const [facebook, setFacebook] = useState('');
    const [isImage1Selected, setIsImage1Selected] = useState(false);
    const [isImage2Selected, setIsImage2Selected] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [usuario, setUsuario] = useState({});
    const [categorias, setCategorias] = useState([]);
    const [categoria, setCategoria] = useState('');
    const [estado, setEestado] = useState('visible');
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const handleImagenChange = (event, setImagenPreview, setIsImageSelected) => {
        const file = event.target.files[0];
        if (file) {
            const previewURL = URL.createObjectURL(file);
            setImagenPreview(previewURL);
            setIsImageSelected(true);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${baseURL}/userLogued.php`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setUsuario(data);
            } catch (error) {
                console.error('Error al obtener datos:', error);
            }
        };
        fetchData();
    }, []);

    const idUsuario = usuario.idUsuario;
    const rolUsuario = usuario.rol;
    const crear = async () => {
        const form = document.getElementById("crearForm");
        const formData = new FormData(form);

        const resetForm = () => {
            form.reset();
            setImagenPreview1(null);
            setImagenPreview2(null);
        };

        setMensaje('');

        if (
            !formData.get('nombre') ||
            !formData.get('telefono') ||
            !formData.get('instagram') ||
            !formData.get('email') ||
            !formData.get('direccion') ||
            !formData.get('facebook') ||
            !formData.get('imagen1') ||
            !formData.get('imagen2') ||
            !idUsuario
        ) {
            toast.error('Por favor, complete todos los campos correctamente.');
            return;
        }

        setMensaje('Procesando...');

        formData.append('idUsuario', idUsuario); // Añadir idUsuario al formData

        try {
            const response = await fetch(`${baseURL}/tiendaPost.php`, {
                method: 'POST',
                body: formData
            });

            const data = await response.json();

            if (data.mensaje) {
                setMensaje('');
                resetForm();
                toast.success(data.mensaje);
                window.location.reload();
            } else if (data.error) {
                setMensaje('');
                toast.error(data.error);
                console.log(data.error);
            }
        } catch (error) {
            console.error('Error:', error);
            setMensaje('');
            toast.error('Error de conexión. Por favor, inténtelo de nuevo.');
        }
    };
    const handleCategoriaChange = (e) => {
        setCategoria(e.target.value);
    };
    const handleEstadoChange = (e) => {
        setEestado(e.target.value);
    };
    useEffect(() => {
        cargarCategorias();
    }, []);

    const cargarCategorias = () => {
        fetch(`${baseURL}/categoriaTiendaGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setCategorias(data.categorias || []);
                console.log(data.categorias)
            })
            .catch(error => console.error('Error al cargar contactos:', error));
    };
    return (
        <div className='NewContain'>
            <ToastContainer />
            <button onClick={toggleModal} className='btnSave'>
                <span> +</span> Agregar
            </button>
            {modalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <div className='deFlexBtnsModal'>
                            <button className='selected'>Agregar Tienda</button>
                            <span className="close" onClick={toggleModal}>&times;</span>
                        </div>
                        <form id="crearForm">
                            <div className='previevImagesLogoBanner'>
                                {isImage2Selected && <img src={imagenPreview2} alt="Vista previa 2" className='previevImagesBanner' />}
                                {isImage1Selected && <img src={imagenPreview1} alt="Vista previa 1" className='previevImagesLogo' />}

                            </div>
                            <div className='flexGrap'>

                                <fieldset id='deNoneInput'>
                                    <legend>IdUsuario</legend>
                                    <input
                                        type="number"
                                        id="idUsuario"
                                        name="idUsuario"
                                        required
                                        value={idUsuario}
                                        readOnly
                                    />
                                </fieldset>
                                <fieldset id='deNoneInput'>
                                    <legend>rolUsuario</legend>
                                    <input
                                        type="text"
                                        id="rol"
                                        name="rol"
                                        required
                                        value={rolUsuario}
                                        readOnly
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Logo</legend>
                                    <input
                                        type="file"
                                        id="imagen1"
                                        name="imagen1"
                                        accept="image/*"
                                        onChange={(e) => handleImagenChange(e, setImagenPreview1, setIsImage1Selected)}
                                        required
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Banner</legend>
                                    <input
                                        type="file"
                                        id="imagen2"
                                        name="imagen2"
                                        accept="image/*"
                                        onChange={(e) => handleImagenChange(e, setImagenPreview2, setIsImage2Selected)}
                                        required
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Nombre</legend>
                                    <input
                                        type="text"
                                        id="nombre"
                                        name="nombre"
                                        required
                                        value={nombre}
                                        onChange={(e) => setNombre(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Telefono</legend>
                                    <input
                                        type="text"
                                        id="telefono"
                                        name="telefono"
                                        required
                                        value={telefono}
                                        onChange={(e) => setTelefono(e.target.value)}
                                    />
                                </fieldset>

                                <fieldset>
                                    <legend>Categoría</legend>
                                    <select
                                        id="idCategoria"
                                        name="idCategoria"
                                        value={categoria}
                                        onChange={handleCategoriaChange}
                                    >
                                        <option value="">Selecciona una categoría</option>
                                        {categorias.map(item => (
                                            <option key={item.idCategoria} value={item.idCategoria}>{item.categoria}</option>
                                        ))}
                                    </select>
                                </fieldset>
                                <fieldset>
                                    <legend>Estado</legend>
                                    <select
                                        id="estado"
                                        name="estado"
                                        value={estado}
                                        onChange={handleEstadoChange}
                                    >

                                        <option value='visible'>Visible</option>
                                        <option value='no_visible'>No visible</option>

                                    </select>
                                </fieldset>
                                <fieldset>
                                    <legend>Instagram</legend>
                                    <input
                                        type="text"
                                        id="instagram"
                                        name="instagram"
                                        required
                                        value={instagram}
                                        onChange={(e) => setInstagram(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Email</legend>
                                    <input
                                        type="text"
                                        id="email"
                                        name="email"
                                        required
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Direccion</legend>
                                    <input
                                        type="text"
                                        id="direccion"
                                        name="direccion"
                                        required
                                        value={direccion}
                                        onChange={(e) => setDireccion(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Facebook</legend>
                                    <input
                                        type="text"
                                        id="facebook"
                                        name="facebook"
                                        required
                                        value={facebook}
                                        onChange={(e) => setFacebook(e.target.value)}
                                    />
                                </fieldset>

                            </div>

                            {mensaje ? (
                                <button type="button" className='btnLoading' disabled>
                                    {mensaje}
                                </button>
                            ) : (
                                <button type="button" onClick={crear} className='btnPost'>
                                    Agregar
                                </button>
                            )}
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
}

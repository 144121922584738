import React from 'react'
import Tienda from '../../Components/Tienda/Tienda'
import './PageTienda.css'
export default function PageTienda() {
    return (
        <div className='pageDetail'>
            <Tienda />
        </div>
    )
}

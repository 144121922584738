import React from 'react'
import Banners from '../../Components/Banners/Banners'
import Tiendas from '../../Components/Tiendas/Tiendas'
import './Demo.css'
import Footer from '../../Components/Footer/Footer'
import BtnWhatsapp from '../../Components/BtnWhatsapp/BtnWhatsapp'
import TiendaSocial from '../../Components/TiendaSocial/TiendaSocial'
import BannersTiendas from '../../Components/BannersTiendas/BannersTiendas'
import { useMediaQuery } from '@react-hook/media-query';
export default function Demo() {
    const isScreenLarge = useMediaQuery('(min-width: 900px)');
    return (
        <section className='demo'>
            <Banners />
            <BannersTiendas />
            <Tiendas />
            <TiendaSocial />
            <Footer />
            {isScreenLarge ?
                <>
                    <BtnWhatsapp />
                </> :
                <>

                </>}
        </section>
    )
}

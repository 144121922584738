import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useMediaQuery } from '@react-hook/media-query';
import Nabvar from '../Components/Navbar/Navbar';
import Footer from '../Components/Footer/Footer';
import FooterTIienda from '../Components/FooterTIienda/FooterTIienda';
import BtnWhatsapp from '../Components/BtnWhatsapp/BtnWhatsapp';
import Cart from '../Components/Cart/Cart';

export default function PageLayout() {
    const isScreenLarge = useMediaQuery('(min-width: 900px)');
    const location = useLocation();

    const isTiendasPage = location.pathname === '/tiendas';

    return (
        <div>
            {isScreenLarge ? (
                <>
                    <Nabvar />
                    <Outlet />
                    {isTiendasPage ? <Footer /> : <FooterTIienda />}
                    <BtnWhatsapp />
                    <Cart />
                </>
            ) : (
                <div className='section-bg-color'>
                    <Nabvar />
                    <div className='espaciobg'></div>
                    <Outlet />
                    {isTiendasPage ? <Footer /> : <FooterTIienda />}
                    <BtnWhatsapp />
                    <Cart />
                </div>
            )}
            <div className='espaciobg2'></div>
        </div>
    );
}

import React, { useEffect, useState } from 'react';
import './Footer.css'
import { Link as Anchor } from 'react-router-dom';
import logo from '../../images/logo.png'
import baseURL from '../url';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import AuthUsuario from '../AuthUsuario/AuthUsuario'

export default function Footer() {
    const [contactos, setContactos] = useState([]);
    const [tiendas, setTiendas] = useState([]);

    useEffect(() => {
        cargarContacto();
        cargarTiendas();
    }, []);

    const cargarContacto = () => {
        fetch(`${baseURL}/contactoGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setContactos(data.contacto.reverse()[0] || []);
            })
            .catch(error => console.error('Error al cargar contactos:', error));
    };

    const cargarTiendas = () => {
        fetch(`${baseURL}/tiendasFrontend.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                const shuffledTiendas = data.tiendas.sort(() => 0.5 - Math.random());
                setTiendas(shuffledTiendas.slice(0, 3) || []);
                console.log(data.tiendas);
            })
            .catch(error => console.error('Error al cargar datos:', error));
    };

    return (
        <div className='FooterContain'>
            <div className='footerText'>
                <Anchor to='' target="_blank"> <img src={logo} alt="logo" /></Anchor>
                <h2>
                    {contactos.nombre}
                </h2>
                <div className='socials'>
                    <Anchor to={contactos.instagram} target="_blank"><i className='fa fa-instagram'></i></Anchor>
                    <Anchor to={`tel:${contactos.telefono}`} target="_blank"><i className='fa fa-whatsapp'></i></Anchor>
                    <Anchor to={contactos.facebook} target="_blank"><i className='fa fa-facebook'></i></Anchor>
                </div>
            </div>
            <div className='footerText'>
                <h3>Contacto</h3>
                <Anchor to={`mailto:${contactos.email}`} target="_blank">{contactos.email}</Anchor>
                <Anchor to={`tel:${contactos.telefono}`} target="_blank">{contactos.telefono}</Anchor>
                <Anchor to={`https://www.google.com/maps?q=${encodeURIComponent(contactos.direccion)}`} target="_blank">{contactos.direccion}</Anchor>
            </div>
            <div className='footerText'>
                <h3>Tiendas</h3>
                {
                    tiendas?.map(item => (
                        <Anchor key={item.idTienda} to={`/tienda/${item.idTienda}/${item.nombre.replace(/\s+/g, '-')}`}>{item?.nombre?.slice(0, 33)}</Anchor>
                    ))
                }
            </div>
            <div className='footerText'>
                <h3>Acceso</h3>
                <Anchor to={'/dashboard'} className='authButton'> <FontAwesomeIcon icon={faUser} /> Dashboard</Anchor>
                <AuthUsuario />
            </div>
        </div>
    );
}

import IndexLayout from "../Layouts/IndexLayout";
import MainLayout from "../Layouts/MainLayout";
import PagesLayaut from '../Layouts/PagesLayaut'
import PageLayaut from '../Layouts/PageLayaut'
import { createBrowserRouter } from "react-router-dom";
import Productos from '../Pages/Productos/Productos'
import Usuarios from '../Pages/Usuarios/Usuarios'
import Banners from "./Banners/Banners";
import Main from "./Main/Main";
import Contacto from "./Contacto/Contacto";
import Categorias from "./Categorias/Categorias";
import CategoriasTiendas from "./CategoriasTiendas/CategoriasTiendas";
import Codigos from "./Codigos/Codigos";
import PageDetail from '../Pages/PageDetail/PageDetail';
import PageTienda from '../Pages/PageTienda/PageTienda';
import TiendasPage from '../Pages/TiendasPage/TiendasPage';
import Tiendas from "./Tiendas/Tiendas";

export const router = createBrowserRouter([

    {
        path: "/",
        element: <IndexLayout />,

    },
    {
        path: "/",
        element: <PagesLayaut />,
        children: [
            {
                path: `/producto/:idProducto/:producto`,
                element: <PageDetail />,
            },


        ]
    },
    {
        path: "/",
        element: <PageLayaut />,
        children: [

            {
                path: `/tienda/:idTienda/:nombre`,
                element: <PageTienda />,
            },
            {
                path: `/tiendas`,
                element: <TiendasPage />,
            },


        ]
    },
    {
        path: "/",
        element: <MainLayout />,
        children: [
            {
                path: `/dashboard`,
                element: <Main />,
            },
            {
                path: `/dashboard/productos`,
                element: <Productos />,
            },
            {
                path: `/dashboard/usuarios`,
                element: <Usuarios />,
            },
            {
                path: `/dashboard/banners`,
                element: <Banners />,
            },
            {
                path: `/dashboard/contacto`,
                element: <Contacto />,
            },
            {
                path: `/dashboard/categorias`,
                element: <Categorias />,
            },
            {
                path: `/dashboard/codigos`,
                element: <Codigos />,
            },
            {
                path: `/dashboard/tiendas`,
                element: <Tiendas />,
            },
            {
                path: `/dashboard/categorias-tiendas`,
                element: <CategoriasTiendas />,
            },
        ],
    },


]);

import React, { useState, useEffect } from 'react';
import './NewProduct.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import baseURL from '../../url';

export default function NewProduct() {
    const [mensaje, setMensaje] = useState('');
    const [imagenPreview1, setImagenPreview1] = useState(null);
    const [imagenPreview2, setImagenPreview2] = useState(null);
    const [imagenPreview3, setImagenPreview3] = useState(null);
    const [imagenPreview4, setImagenPreview4] = useState(null);
    const [descripcion, setDescripcion] = useState('');
    const [titulo, setTitulo] = useState('');
    const [categoria, setCategoria] = useState('');
    const [masVendido, setMasVendido] = useState('');
    const [precio, setPrecio] = useState('');
    const [isImage1Selected, setIsImage1Selected] = useState(false);
    const [isImage2Selected, setIsImage2Selected] = useState(false);
    const [isImage3Selected, setIsImage3Selected] = useState(false);
    const [isImage4Selected, setIsImage4Selected] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [categorias, setCategorias] = useState([]);
    const [item1, setItem1] = useState('');
    const [item2, setItem2] = useState('');
    const [item3, setItem3] = useState('');
    const [item4, setItem4] = useState('');
    const [item5, setItem5] = useState('');
    const [item6, setItem6] = useState('');
    const [item7, setItem7] = useState('');
    const [item8, setItem8] = useState('');
    const [item9, setItem9] = useState('');
    const [item10, setItem10] = useState('');
    const [precioAnterior, setPrecioAnterior] = useState('');
    const [stock, setStock] = useState('');
    const [usuario, setUsuario] = useState({});
    const [tiendas, setTiendas] = useState([]);
    const [idTiendaSeleccionada, setIdTiendaSeleccionada] = useState('');

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const handleImagenChange = (event, setImagenPreview, setIsImageSelected) => {
        const file = event.target.files[0];

        if (file) {
            const previewURL = URL.createObjectURL(file);
            setImagenPreview(previewURL);
            setIsImageSelected(true);
        }
    };

    const crear = async () => {
        const form = document.getElementById("crearForm");
        const formData = new FormData(form);

        // Agregar idUsuario e idTienda al formData
        formData.append('idUsuario', usuario.idUsuario);
        formData.append('idTienda', idTiendaSeleccionada);

        const resetForm = () => {
            form.reset();
            setImagenPreview1(null);
            setImagenPreview2(null);
            setImagenPreview3(null);
            setImagenPreview4(null);
            setIsImage1Selected(false);
            setIsImage2Selected(false);
            setIsImage3Selected(false);
            setIsImage4Selected(false);
        };
        setMensaje('');

        // Validar que todos los campos estén completos
        if (
            !formData.get('descripcion') ||
            !formData.get('titulo') ||
            !formData.get('idCategoria') ||
            !formData.get('masVendido') ||
            !formData.get('precio') ||
            !formData.get('imagen1') ||
            !formData.get('imagen2') ||
            !formData.get('imagen3') ||
            !formData.get('imagen4')
        ) {
            toast.error('Por favor, complete todos los campos correctamente.');
            return;
        }

        setMensaje('Procesando...');

        try {
            const response = await fetch(`${baseURL}/productosPost.php`, {
                method: 'POST',
                body: formData
            });

            const data = await response.json();

            if (data.mensaje) {
                setMensaje('');
                resetForm();
                toast.success(data.mensaje);
                window.location.reload();
            } else if (data.error) {
                setMensaje('');
                toast.error(data.error);
                console.log(data.error);
            }
        } catch (error) {
            console.error('Error:', error);
            setMensaje('');
            toast.error('Error de conexión. Por favor, inténtelo de nuevo.');
        }
    };

    const handleCategoriaChange = (e) => {
        setCategoria(e.target.value);
    };

    const handleMasVendidoChange = (e) => {
        setMasVendido(e.target.value);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${baseURL}/userLogued.php`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setUsuario(data);
            } catch (error) {
                console.error
                    ('Error al obtener datos:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        cargarTiendas();
    }, []);

    const idUsuarioPost = usuario.idUsuario;
    const rolUsuarioPost = usuario.rol;
    const cargarTiendas = () => {
        fetch(`${baseURL}/tiendaGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setTiendas(data.tiendas || []);
            })
            .catch(error => console.error('Error al cargar datos:', error));
    };


    useEffect(() => {
        cargarCategorias();
    }, []);

    const cargarCategorias = () => {
        fetch(`${baseURL}/categoriasGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setCategorias(data.categorias || []);
                console.log(data.categorias)
            })
            .catch(error => console.error('Error al cargar contactos:', error));
    };


    return (
        <div className='NewContain'>
            <ToastContainer />
            <button onClick={toggleModal} className='btnSave'>
                <span>  +</span> Agregar
            </button>
            {modalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <div className='deFlexBtnsModal'>
                            <button className='selected'>Agregar Producto</button>
                            <span className="close" onClick={toggleModal}>&times;</span>
                        </div>
                        <form id="crearForm">
                            <div className='flexGrap'>
                                <fieldset id='deNoneInput'>
                                    <legend>IdUsuario</legend>
                                    <input
                                        type="number"
                                        id="idUsuario"
                                        name="idUsuario"
                                        required
                                        value={idUsuarioPost}
                                        readOnly
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Título</legend>
                                    <input
                                        type="text"
                                        id="titulo"
                                        name="titulo"
                                        required
                                        value={titulo}
                                        onChange={(e) => setTitulo(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Tienda</legend>
                                    <select
                                        name="idTienda"
                                        id="idTienda"
                                        value={idTiendaSeleccionada}
                                        onChange={(e) => setIdTiendaSeleccionada(e.target.value)}
                                    >
                                        <option value="">Selecciona una tienda</option>
                                        {usuario.rol === 'admin' ? (
                                            tiendas.map(item => (
                                                <option key={item.idTienda} value={item.idTienda}>{item.nombre}</option>
                                            ))
                                        ) : (
                                            tiendas
                                                .filter(item => item.idUsuario === usuario.idUsuario)
                                                .map(item => (
                                                    <option key={item.idTienda} value={item.idTienda}>{item.nombre}</option>
                                                ))
                                        )}
                                    </select>
                                </fieldset>

                                <fieldset>
                                    <legend>Precio</legend>
                                    <input
                                        type="number"
                                        id="precio"
                                        name="precio"
                                        min="0"
                                        step="0.01"
                                        required
                                        value={precio}
                                        onChange={(e) => setPrecio(e.target.value)}
                                    />
                                </fieldset>

                                <fieldset>
                                    <legend>Categoría</legend>
                                    <select
                                        id="idCategoria"
                                        name="idCategoria"
                                        value={categoria}
                                        onChange={handleCategoriaChange}
                                    >
                                        <option value="">Selecciona una categoría</option>
                                        {categorias.map(item => (
                                            <option key={item.idCategoria} value={item.idCategoria}>{item.categoria}</option>
                                        ))}
                                    </select>
                                </fieldset>
                                <fieldset>
                                    <legend>Más vendido</legend>
                                    <select
                                        id="masVendido"
                                        name="masVendido"
                                        value={masVendido}
                                        onChange={handleMasVendidoChange}
                                    >
                                        <option value="">Selecciona opción</option>
                                        <option value="si">Sí</option>
                                        <option value="no">No</option>
                                    </select>
                                </fieldset>
                                <fieldset>
                                    <legend>Stock</legend>
                                    <input
                                        type="number"
                                        id="stock"
                                        name="stock"
                                        min="0"
                                        step="0.01"
                                        required
                                        value={stock}
                                        onChange={(e) => setStock(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset id='descripcion'>
                                    <legend>Descripción</legend>
                                    <textarea
                                        id="descripcion"
                                        name="descripcion"
                                        required
                                        value={descripcion}
                                        onChange={(e) => setDescripcion(e.target.value)}
                                        placeholder="Descripción"
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Precio anterior</legend>
                                    <input
                                        type="number"
                                        id="precioAnterior"
                                        name="precioAnterior"
                                        min="0"
                                        step="0.01"
                                        required
                                        value={precioAnterior}
                                        onChange={(e) => setPrecioAnterior(e.target.value)}
                                    />
                                </fieldset>
                                <div className='items'>
                                    {[...Array(10)].map((_, index) => (
                                        <fieldset key={index}>
                                            <legend>Item {index + 1}</legend>
                                            <input
                                                type="text"
                                                id={`item${index + 1}`}
                                                name={`item${index + 1}`}
                                                required
                                                value={eval(`item${index + 1}`)}
                                                onChange={(e) => eval(`setItem${index + 1}`)(e.target.value)}
                                            />
                                        </fieldset>
                                    ))}
                                </div>
                                {[...Array(4)].map((_, index) => (
                                    <fieldset key={index}>
                                        <legend>Imagen {index + 1}</legend>
                                        <input
                                            type="file"
                                            id={`imagen${index + 1}`}
                                            name={`imagen${index + 1}`}
                                            accept="image/*"
                                            onChange={(e) => handleImagenChange(e, eval(`setImagenPreview${index + 1}`), eval(`setIsImage${index + 1}Selected`))}
                                            required
                                        />
                                    </fieldset>
                                ))}
                            </div>
                            <div className='previevCategori'>
                                {isImage1Selected && <img src={imagenPreview1} alt="Vista previa 1" />}
                                {isImage2Selected && <img src={imagenPreview2} alt="Vista previa 2" />}
                                {isImage3Selected && <img src={imagenPreview3} alt="Vista previa 3" />}
                                {isImage4Selected && <img src={imagenPreview4} alt="Vista previa 4" />}
                            </div>
                            {mensaje ? (
                                <button type="button" className='btnLoading' disabled>
                                    {mensaje}
                                </button>
                            ) : (
                                <button type="button" onClick={crear} className='btnPost'>
                                    Agregar
                                </button>
                            )}
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
}

import React from 'react'
import './TiendaLoading.css'
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import ProductosLoading from '../ProductosLoading/ProductosLoading';
SwiperCore.use([Navigation, Pagination, Autoplay]);
export default function TiendaLoading() {
    return (
        <div className='loadingContainTienda'>

            <div className='loadingTienda'>

            </div>

            <div className='loadingImgPerfil'>

            </div>
            <ProductosLoading />

        </div>
    )
}
